export const imagePath =  {
    shrigaon_attraction : require('../images/event-raj-12.a5c99bd1ea3e5abfcc25.jpg'),
    panjkot : require('../images/green-bann.68912b47be5d0046ec7b.jpg'),
    beach_kelva : require('../images/event-raj-6.a9803706ed220a76b3ed.png'),
    jpeg_optimizer_bar_img : require('../images/jpeg-optimizer_bar-img.3974a95ac0359005818f.jpeg'),
    raj_room: require('../images/green-bann.68912b47be5d0046ec7b.jpg'),
    gannn: require('../images/20200214_195130_Original.89dd1d3bf96c24ad552f.jpg'),
    resto:require('../images/ourRestaurant.jpg'),
    bg_dot:require('../images/bg-dots.jpg'),
    logo:require('../images/raj-logo-removebg-preview.c85a7fee59978f7e34ac.png'),
    images24: require('../images/image24.avif'),
    images25: require('../images/lord-ganesh.jpg'),
    eventOne: require('../images/stage.jpg'),
    eventOnee: require('../images/event-raj-6.a9803706ed220a76b3ed.png'),
    eventOneee: require('../images/event-raj-12.a5c99bd1ea3e5abfcc25.jpg'),
    eventOneeee: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    aboutbg: require('../images/raj-logo-removebg-preview.c85a7fee59978f7e34ac.png'),
    cl2:require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    cl3: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    cl4 : require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    room1 : require('../images/room1.jpg'),
    room2: require('../images/room2.avif'),
    room3: require('../images/room3.jpg'),
    room4: require('../images/room4.jpg'),
    room5: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    room6: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pool1 : require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pool2: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pool3: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pool4: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pool5: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    wed: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    bday: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    mile : require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    pagebanner: require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    bann:  require('../images/event-raj-9.05cae98dc8c4f2078de0.jpg'),
    wed1: require('../images/wed1.avif'),
    wed2: require('../images/wed2.avif'),
    wed3: require('../images/wed3.avif'),
    wed4: require('../images/wed4.avif'),
    wed5: require('../images/wed5.avif'),
    wed6: require('../images/wed6.avif'),
    wed7: require('../images/wed7.jpg'),
    bday1: require('../images/bday1.jpg'),
    bday2: require('../images/bday2.png'),
    event1: require('../images/event1.avif'),
    event2: require('../images/event2.avif'),
    event3: require('../images/event3.jpg'),
    event4: require('../images/event-raj-6.a9803706ed220a76b3ed.png'),
    eventbg: require('../images/aboutg.jpg'),
    contactbg: require('../images/contactbg.avif'),
    kelvaDam:require('../images/kelvaDam.avif'),
    panjkot:require('../images/panjkot.avif'),
    kelvabeach: require('../images/kelvabeach.avif'),
    kill: require('../images/killa.avif'),
}